import React, { useState } from 'react';
import BookRecommendationForm from './components/BookRecommendationForm';
import BookList from './components/BookList';
import chatGPTService from './services/chatGPTService';

const App = () => {
  const [books, setBooks] = useState([]);

  const handleRecommend = async (userData) => {
    const response = await chatGPTService(userData);
    
    // Ensure response is an array before setting state
    if (Array.isArray(response)) {
      setBooks(response);
    } else {
      console.error("Unexpected response format:", response);
      setBooks([]);
    }
  };

  return (
    <div>
      <h1>Custom Book Recommendations</h1>
      <BookRecommendationForm onRecommend={handleRecommend} />
      <BookList books={books} />
    </div>
  );
};

export default App;