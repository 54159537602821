import axios from 'axios';

const chatGPTService = async (userData) => {
  const prompt = generatePrompt(userData);

  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
//        model: 'gpt-4',
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'system', content: 'You are a helpful assistant.' }, { role: 'user', content: prompt }],
      },
      {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );

    const content = response.data.choices[0].message.content;

    // Parse the JSON string within content
    try {
      const parsedData = JSON.parse(content);
      return parsedData.books;  // Assuming the response format includes a "books" array
    } catch (error) {
      // Log the error in a simpler way to avoid syntax issues
      console.error("Failed to parse JSON:", error);
      console.error("Response content:", content);
      return [];
    }

  } catch (error) {
    console.error('Error with the API request:', error);
    throw new Error('Failed to fetch recommendations. Please try again later.');
  }
};

/* const generatePrompt = (userData) => {
    // Determine the country-specific filter
    let countryFilter = '';
    if (userData.country === 'Korea') {
      countryFilter = 'Please recommend books that were published in Korea.';
    } else {
      countryFilter = 'Please recommend books that were published outside of Korea.';
    }
  
    return `Based on the following user details, recommend some books in the following JSON format:
    {
      "books": [
        {
          "title": "Book Title",
          "coverImage": "URL to cover image",
          "recommendationReason": "Reason for recommendation",
          "tableOfContents": "Table of contents",
          "purchaseLink": "URL to purchase the book"
        }
      ]
    }
  
    ${countryFilter}
    
    User details:
    Personality: ${userData.personality}
    Values: ${userData.values}
    Interests: ${userData.interests}
    MBTI: ${userData.mbti}
    Publication Year: ${userData.publicationYear}
    Country: ${userData.country}
    Genre: ${userData.genre}
    Number of Books: ${userData.numberOfBooks}`;
  }; */

  const generatePrompt = (userData) => {
    // Determine the country-specific filter
    let countryFilter = '';
    if (userData.country === 'Korea') {
      countryFilter = '추천 도서 목록은 반드시 한국에서 출판된 도서만 포함되어야 하며, 한국 작가의 작품을 우선으로 고려해 주세요.';
    } else {
      countryFilter = '한국 외 국가에서 출판된 책을 추천해 주세요.';
    }

    return `다음 사용자의 세부 정보를 기반으로 책을 추천해 주세요. 다음 JSON 형식으로 추천해 주세요:
    {
      "books": [
        {
          "title": "책 제목",
          "coverImage": "표지 이미지 URL",
          "recommendationReason": "추천 이유",
          "tableOfContents": "목차",
          "purchaseLink": "도서 구매 링크"
        }
      ]
    }
    
    ${countryFilter}

    한국어로 응답해 주세요.
    
    사용자 세부 사항:
    성격: ${userData.personality}
    가치관: ${userData.values}
    관심사: ${userData.interests}
    MBTI: ${userData.mbti}
    출판 연도: ${userData.publicationYear}
    국가: ${userData.country}
    장르: ${userData.genre}
    추천 도서 수: ${userData.numberOfBooks}`;

};

export default chatGPTService;