import React from 'react';
import BookItem from './BookItem';

const BookList = ({ books }) => {
  // Log the image URL to debug
console.log('Book Image URL:', books.coverImage);
  // Ensure books is an array before mapping
  if (!Array.isArray(books) || books.length === 0) {
    return <p>No books to display</p>;
  }

  return (
    <div>
      {books.map((book, index) => (
        <BookItem key={index} book={book} />
      ))}
    </div>
  );
};

export default BookList;