import React from 'react';

const BookItem = ({ book }) => {
  // Generate a Korean bookstore purchase link
  const getKoreanPurchaseLink = (bookTitle) => {
    const simplifiedTitle = bookTitle.replace(/[^a-zA-Z0-9가-힣\s]/g, '').trim();
    const encodedTitle = encodeURIComponent(simplifiedTitle);

    return `https://search.kyobobook.co.kr/search?keyword=${encodedTitle}&gbCode=TOT&target=total`;
  };

//  const purchaseLink = book.purchaseLink || getKoreanPurchaseLink(book.title);
  const purchaseLink = getKoreanPurchaseLink(book.title);

  return (
    <div className="book-item">
      <img src={book.coverImage} alt={`${book.title} 표지`} />
      <h3>{book.title}</h3>
      <p>{book.recommendationReason}</p>
      <p>{book.tableOfContents}</p>
      <a href={purchaseLink} target="_blank" rel="noopener noreferrer">지금 구매하세요</a>
    </div>
  );
};

export default BookItem;