import React, { useState } from 'react';

const BookRecommendationForm = ({ onRecommend }) => {
  const [formData, setFormData] = useState({
    personality: '',
    values: '',
    interests: '',
    mbti: '',
    publicationYear: 'within 1 year',
    country: 'Korea',
    genre: 'All Genres',
    numberOfBooks: 5,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onRecommend(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Personality:
        <select name="personality" value={formData.personality} onChange={handleChange}>
          <option value="">Select Personality</option>
          <option value="Introverted">Introverted</option>
          <option value="Extroverted">Extroverted</option>
          <option value="Ambivert">Ambivert</option>
          {/* Add more options as needed */}
        </select>
      </label>
      
      <label>
        Values:
        <select name="values" value={formData.values} onChange={handleChange}>
          <option value="">Select Values</option>
          <option value="Integrity">Integrity</option>
          <option value="Courage">Courage</option>
          <option value="Compassion">Compassion</option>
          {/* Add more options as needed */}
        </select>
      </label>

      <label>
        Interests:
        <select name="interests" value={formData.interests} onChange={handleChange}>
          <option value="">Select Interests</option>
          <option value="Technology">Technology</option>
          <option value="Art">Art</option>
          <option value="Science">Science</option>
          {/* Add more options as needed */}
        </select>
      </label>

      <label>
        MBTI:
        <select name="mbti" value={formData.mbti} onChange={handleChange}>
          <option value="">Select MBTI</option>
          <option value="INTJ">INTJ</option>
          <option value="ENTP">ENTP</option>
          <option value="ISFJ">ISFJ</option>
          <option value="ESFP">ESFP</option>
          {/* Add more options as needed */}
        </select>
      </label>

      <label>
        Publication Year:
        <select name="publicationYear" value={formData.publicationYear} onChange={handleChange}>
          <option value="within 1 year">Within 1 Year</option>
          <option value="within 3 years">Within 3 Years</option>
          <option value="within 5 years">Within 5 Years</option>
        </select>
      </label>

      <label>
        Country:
        <select name="country" value={formData.country} onChange={handleChange}>
          <option value="Korea">Korea</option>
          <option value="International">International</option>
        </select>
      </label>

      <label>
        Genre:
        <select name="genre" value={formData.genre} onChange={handleChange}>
          <option value="All Genres">All Genres</option>
          <option value="Fiction">Fiction</option>
          <option value="Non-Fiction">Non-Fiction</option>
          <option value="Science Fiction">Science Fiction</option>
          <option value="Biography">Biography</option>
          <option value="History">History</option>
          <option value="Fantasy">Fantasy</option>
          <option value="Romance">Romance</option>
          {/* Add more options as needed */}
        </select>
      </label>

      <label>
        Number of Books:
        <input
          type="number"
          name="numberOfBooks"
          min="1"
          max="10"
          value={formData.numberOfBooks}
          onChange={handleChange}
        />
      </label>
      
      <button type="submit">Get Recommendations</button>
    </form>
  );
};

export default BookRecommendationForm;